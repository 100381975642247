@media (max-width: 1300px) {
  .popup.type-iframe {
    min-width: 80% !important;
  }
}

@media (max-width: 1100px) {
  .layout > .bottom-navigation {
    background-color: var(--theme-color);
    border-radius: 40px;
    /* padding: 0px 35px;
		right: 2%;
		width: 13%;
		height: 70px; */
    right: 15px;
    transition: all ease 0.5s;
    justify-content: space-evenly;
    width: 70px;
    height: 70px;
  }

  .layout > .bottom-navigation.open {
    width: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .layout > .bottom-navigation.open.home {
    width: 30%;
  }

  .layout > .bottom-navigation li {
    position: absolute;
    right: 1%;
    transform: scale(0);
    transition: all ease 0.1s;
  }

  .layout > .bottom-navigation.open li {
    position: static;
    transform: scale(1);
  }

  .layout > .bottom-navigation.open li span {
    width: auto;
    height: auto;
  }

  .layout > .bottom-navigation.home .plus {
    width: 75%;
  }

  .bottom-navigation.home .plus:after,
  .bottom-navigation.home .plus:before {
    left: 25px;
  }

  .layout > .bottom-navigation .plus {
    transform: scale(1);
    position: relative;
    display: flex;
    z-index: 99;
    justify-content: space-evenly;
    width: 85%;
    margin: 0 auto;
    padding-left: 25px;
    align-items: center;
  }

  .plus:after {
    content: " ";
    background-color: #fff;
    width: 2px;
    height: 30px;
    display: block;
    transform: rotate(0deg);
    position: absolute;
    left: 30px;
    top: 20px;
  }

  .layout > .bottom-navigation .cross:after {
    transform: rotate(40deg);
    left: 0px;
    top: 16px;
  }

  .plus:before {
    content: " ";
    background-color: #fff;
    width: 2px;
    height: 30px;
    display: block;
    transform: rotate(90deg);
    position: absolute;
    left: 30px;
    top: 20px;
  }

  .layout > .bottom-navigation .cross:before {
    transform: rotate(140deg);
    left: 0px;
    top: 16px;
  }

  .layout > .bottom-navigation li span {
    padding: 0;
  }

  .layout > .bottom-navigation li span i {
    display: block;
    font-size: 0.8rem;
    position: static;
    color: #fff;
    width: 100%;
  }
}

@media (max-width: 768px) {
  /* .pdf-title {
    max-height: 83px !important;
    height: 100%;
  } */

  .bottom-navigation {
    z-index: 7;
  }
  .page-thumb-width {
    width: 172px !important;
  }
  .site-header-scroll {
    position: absolute !important;
    width: 100%;
  }
  .moreimagedivmain {
    flex-direction: column-reverse;
  }
  .moreimagediv {
    overflow-x: scroll;
    width: 95%;
    flex-direction: row !important;
  }
  /* .moreimagedivmain .imagediv img {
    height: 70%;
    width: 70%;
  } */
  .moreimagediv {
    height: 100%;
  }

  /* new */
  .overlay-banner-logo {
    max-width: 200px;
    height: auto;
  }

  /* new */
  :root {
    font-size: 14px;
  }

  .popup.type-iframe {
    min-height: 50vh !important;
  }

  .displayNoneMobile {
    display: none;
  }

  .mobileCartsection {
    display: block;
    /* height: calc(100vh - 181px);
		overflow-y: auto; */
  }

  .mobileCartsectionContainer {
    display: block;
    /* overflow-y: auto;
		max-height: calc(100vh - 181px); */
  }

  .forOnlyMobileAddToCart {
    width: 95%;
    height: 62vh;
    padding: 5%;
  }

  .mobileCartsectionContainerCollection {
    display: block;
    /* overflow-y: auto;
		height: calc(100vh - 320px); */
  }

  /* #wrapper{
		padding-bottom: 110px;   recently for bottom white bg
	} */

  /* .hot-area .hot-spot.icon-small svg {
		width: 15px !important;
		height: 15px !important;
	}

	.hot-area .hot-spot.icon-medium svg {
		width: 17.5px !important;
		height: 17.5px !important;
	}

	.hot-area .hot-spot.icon-large svg {
		width: 20px !important;
		height: 20px !important;
	} */

  .right-nav .toggle-sidebar > span > span,
  .ecollat-search div .open-search,
  .ecollat-search div.search-close {
    width: 40px;
  }

  .right-nav .toggle-sidebar > span {
    width: 100px;
  }

  .right-nav .toggle-sidebar > span.enabled {
    width: 80px;
  }

  .marginRightMobile {
    margin-right: 3px;
  }

  .ecollat-search {
    position: absolute;
    top: 0;
    max-width: 91%;
    right: 9%;
    min-width: 40px;
  }

  .ecollat-search.fluid {
    right: 0;
    max-width: 100%;
  }

  .ecollat-search.search-with-cart {
    right: 80px !important;
    max-width: calc(100% - 80px) !important;
  }

  .ecollat-search.mv-50 {
    right: 50px;
    max-width: calc(100% - 50px);
  }

  .ecollat-search.mv-100 {
    right: 100px;
  }

  .ecollat-search div.search-open {
    width: 100vw;
  }

  .ecollat-search.mv-50 div.search-open {
    width: calc(100vw - 50px);
  }

  .ecollat-search.mv-100 div.search-open {
    width: calc(100vw - 100px);
  }

  .center-nav {
    width: 100px;
  }

  .newClassForSearchalign {
    position: unset !important;
  }

  .center-nav .nav-btn {
    display: none;
  }

  .center-nav > span,
  .right-nav > span {
    width: 40px;
  }

  .product-drawer {
    max-width: 100%;
    /* padding-top: 10%; */
  }

  .page-selector {
    width: 100%;
    padding-bottom: 50px;
  }

  .pdf-selected-sidebar {
    position: absolute;
    width: 100%;
    right: -100%;
    top: 0;
    transition: right 0.4s ease;
    max-height: 100%;
    z-index: 22;
    /* padding: 15px 10%; */
    padding: 15px 0%;
    padding-bottom: 30px;
  }

  .selected-page-wrapper {
    padding: 5px;
  }

  .pdf-selected-sidebar.open {
    right: 0;
  }

  .pdf-selected-sidebar.open .close-selectedpdfbar {
    display: block;
  }

  .genrate-btn {
    left: 0;
    right: 0;
    margin: 0 auto;
    display: inline-block;
    max-width: 180px;
    z-index: 40;
  }

  .element-pan .hot-spot.has-icon svg {
    transform: scale(1.5);
  }

  .bottom-navigation-mob > ul {
    margin-bottom: 16%;
  }

  .bottom-navigation-mob > ul > li {
    display: inline;
    /* margin: 25px !important; */
  }

  /* .scrollable-list {
		height: 82%;
	} */

  /* .layout > .bottom-navigation {
		display: none;
	} */

  .site-header .nav-btn:hover {
    background: var(--theme-color);
    color: #fff;
  }

  .site-header .nav-btn.bg-arrow {
    background: #ffffff;
    color: var(--theme-color);
  }

  .accordion li:hover > span:not(.accordion-arrow) {
    transform: translateX(0px);
  }

  .pdf-title {
    padding: 12px 30px;
  }

  .layout > .bottom-navigation.open {
    width: 90%;
  }

  .layout > .bottom-navigation.open.home {
    width: 50%;
  }

  .layout > .bottom-navigation .cross:after {
    top: 10px;
  }

  .layout > .bottom-navigation .cross:before {
    top: 10px;
  }

  .plus:after {
    top: -15px;
  }

  .plus:before {
    top: -15px;
  }

  /* mobile Cart start */
  .mobilecartMain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .productImageSec {
    width: 30%;
  }

  .productDetailsSec {
    width: 70%;
  }

  .productImageCart img {
    max-width: 100%;
    height: auto;
  }

  .productNameCross {
    display: flex;
    justify-content: space-between;
  }

  .productPriceandQuantity {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .productPriceandQuantity h3,
  .productName h3 {
    font-size: 16px;
  }

  .marginBottom0 {
    margin-bottom: 0;
  }

  .productPriceCart span {
    font-size: 0.7rem;
  }

  .mobileCartCross span svg {
    width: 24px;
    height: 24px;
  }

  .productDetailsCartMain {
    padding-left: 1rem;
  }

  .productQuantityMain {
    border: 1px solid #929292;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .productQuantityMain p {
    padding: 0.5rem 0.7rem;
    margin-bottom: 0;
    font-size: 1.2rem;
    cursor: pointer;
    height: 2.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .minusFont {
    font-size: 1.4rem !important;
  }

  .productQuantityMain p:nth-child(2) {
    border-left: 1px solid #929292;
    border-right: 1px solid #929292;
    font-size: 1rem;
  }

  .addTagMobile {
    margin: 1rem auto;
  }

  /* mobile Cart end */
  .element-pan .onlyForFirstPageRight {
    right: unset !important;
  }
  .newRightNavAddCss {
    max-width: 370px;
  }
}

@media screen and (max-width: 480px) {
  /* .banner .creator_credits {
    bottom: 70px;
  } */
  .moreimagedivmain .imagediv img {
    height: 70%;
    width: 70%;
  }
  .moreimagediv {
    height: 100%;
  }

  /* For add Product  */
  .mainproductdiv .Centerdiv {
    display: block;
  }
  .mainproductdiv .Centerdiv .moreimagedivmain {
    flex-direction: column-reverse;
    width: 100%;
  }
  .moreimagediv {
    overflow-x: scroll;
    width: 95%;
    flex-direction: row !important;
  }
  .mainproductdiv .Centerdiv .detaildiv {
    margin-top: 0.5rem;
    width: 100%;
  }
  /* .moreimagediv {
    width: 30%;
  } */
  /* End */

  .ecollat-search.mv {
    /* max-width: 70%; */
    right: 13%;
  }
  .search-slider {
    max-width: 100%;
  }
  .newRightNavAddCss {
    max-width: 100%;
  }

  /* .product-drawer {
		padding-top: 15%;
	} */

  .popup-title h2 {
    font-size: 20px;
  }

  .bottom-navigation.home .plus:after,
  .bottom-navigation.home .plus:before {
    left: 18px;
  }

  .center-nav .goto-Handler,
  .center-nav .goto-Handler input {
    width: 70px !important;
  }

  .layout > .bottom-navigation {
    width: 50px;
    height: 50px;
  }

  .layout > .bottom-navigation .plus {
    transform: scale(1);
    position: relative;
    display: flex;
    z-index: 99;
    justify-content: space-evenly;
    width: 85%;
    margin: 0;
    padding-left: 25px;
    align-items: center;
  }

  .plus:after {
    content: " ";
    background-color: #fff;
    width: 2px;
    height: 20px;
    display: block;
    transform: rotate(0deg);
    position: absolute;
    left: 20px;
    top: -10px;
  }

  .layout > .bottom-navigation .cross:after {
    transform: rotate(40deg);
    left: 0px;
    top: 10px;
  }

  .plus:before {
    content: " ";
    background-color: #fff;
    width: 2px;
    height: 20px;
    display: block;
    transform: rotate(90deg);
    position: absolute;
    left: 20px;
    top: -10px;
  }

  .layout > .bottom-navigation .cross:before {
    transform: rotate(140deg);
    left: 0px;
    top: 10px;
  }
}

@media (min-width: 320px) and (max-width: 320px) {
  :root {
    font-size: 12px;
  }

  .nav-logo img {
    max-height: 28px !important;
  }

  .center-nav .goto-Handler input {
    width: 61% !important;
  }

  .app-sidebar {
    width: 320px !important;
  }

  .bottom-navigation-mob > ul {
    margin-bottom: 17%;
  }

  /* .scrollable-list {
		height: 79%;
	} */
}

@media (max-width: 768px) {
  .nav-logo,
  .nav-logo a,
  .isNOtShowHome {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-logo img {
    max-height: auto;
    max-width: 8rem;
  }
}

@media (max-width: 390px) {
  .nav-logo img {
    max-height: auto;
    max-width: 7rem;
  }

  .center-nav .goto-Handler input {
    width: 90%;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }

  .column-6 {
    width: 50%;
  }

  .element-pan .hot-spot.has-icon svg {
    transform: scale(1.8);
  }
}

@media (min-width: 769px) {
  .container {
    max-width: 720px;
  }

  .column-3 {
    width: 25%;
  }
  /* .pdf-title {
    min-height: 50px !important;
  } */

  /* .bottom-navigation li:first-child {
		display: none;
	} */

  .bottom-navigation-mob {
    display: none;
  }

  .layout > .bottom-navigation,
  .layout > .bottom-navigation i {
    display: block;
  }

  .bottom-navigation li {
    margin: 7px auto;
  }

  .bottom-navigation li span {
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
  }

  .bottom-navigation {
    width: auto;
    right: 10px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }

  .cart-table table {
    width: 100%;
  }

  .multi-products table {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
