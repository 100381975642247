* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
html {
  font-size: 1rem;
  box-sizing: border-box;
}
body {
  width: 100%;
  height: 100%;
  color: #272727;
  line-height: 1.9;
  background-color: #f7f5f5;
  font-family: Arial, Helvetica, sans-serif;
}
h2 {
  text-align: center;
  font-size: 2rem;
  line-height: 3.5;
}
.slider {
  position: relative;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.slide {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 1s;
}
.slide > img {
  width: auto;
  height: 100%;
  object-fit: cover;
}
button {
  background: none;
  border: none;
}
button .fas {
  color: rgba(255, 255, 255, 0.5);
}
.btn-slide {
  position: absolute;
  top: 50%;
  z-index: 10;

  height: 3rem;
  width: 5.5rem;
  cursor: pointer;
}
.prev {
  left: 3rem;
  transform: translate(-50%, -50%);
}
.next {
  right: 3rem;
  transform: translate(50%, -50%);
}
.dots-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 0;
  bottom: 3%;
}
.dot {
  width: 25px !important;
  height: 5px !important;
  margin: 15px 5px !important;
  border-radius: 0.5rem;
  background: var(--theme-color);
  opacity: 0.5;
  cursor: pointer;
}
.dot.active {
  background: var(--theme-color) !important;
  opacity: 1 !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  height: 6% !important;
}
.slide img {
  /* color: #252525 !important; */
  border-radius: 0% !important;
  filter: invert(0) !important;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  font-size: 1vw;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: var(--theme-color);
  text-align: center;
}
.carousel-caption h5 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--theme-color);
}
.carousel-caption span {
  color: var(--theme-color);
  font-size: 0.875rem;
  letter-spacing: 0.00088rem;
}
.btn-slide:focus {
  outline: none;
}
