@keyframes Loadin {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
@-webkit-keyframes Loadin {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
@-moz-keyframes Loadin {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
@-ms-keyframes Loadin {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}


@keyframes FlipIn {
    from {
        transform: rotateX(-90deg);
        opacity: 0;
    }

    to {
        transform: rotateX(0deg);
        opacity: 1;
    }
}
@-webkit-keyframes FlipIn {
    from {
        transform: rotateX(-90deg);
        -webkit-transform: rotateX(-90deg);
        opacity: 0;
    }

    to {
        transform: rotateX(0deg);
        -webkit-transform: rotateX(0deg);
        opacity: 1;
    }
}
@-moz-keyframes FlipIn {
    from {
        transform: rotateX(-90deg);
        -moz-transform: rotateX(-90deg);
        opacity: 0;
    }

    to {
        transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        opacity: 1;
    }
}
@-ms-keyframes FlipIn {
    from {
        transform: rotateX(-90deg);
        -ms-transform: rotateX(-90deg);
        opacity: 0;
    }

    to {
        transform: rotateX(0deg);
        -ms-transform: rotateX(0deg);
        opacity: 1;
    }
}




@keyframes Highlight {
    0% {
        background-color: transparent;
    }

    50% {
        /* background-color: rgba(94, 185, 249, 0.40); */
        background-color: var(--theme-color);
        opacity: .6;
    }

    100% {
        background-color: transparent;
    }
}

@-webkit-keyframes Highlight {
    0% {
        background-color: transparent;
    }

    50% {
        /* background-color: rgba(94, 185, 249, 0.40); */
        background-color: var(--theme-color);
        opacity: .6;
    }

    100% {
        background-color: transparent;
    }
}

@-moz-keyframes Highlight {
    0% {
        background-color: transparent;
    }

    50% {
        /* background-color: rgba(94, 185, 249, 0.40); */
        background-color: var(--theme-color);
        opacity: .6;
    }

    100% {
        background-color: transparent;
    }
}

@-ms-keyframes Highlight {
    0% {
        background-color: transparent;
    }

    50% {
        /* background-color: rgba(94, 185, 249, 0.40); */
        background-color: var(--theme-color);
        opacity: .6;
    }

    100% {
        background-color: transparent;
    }
}


/* @keyframes BounceUp {
    0% {
        transform: translateY(10px);
        opacity: 0;
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(10px);
        opacity: 1;
    }
}

@-webkit-keyframes BounceUp {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0px);
    }
}

@-moz-keyframes BounceUp {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0px);
    }
}

@-ms-keyframes BounceUp {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0px);
    }
} */