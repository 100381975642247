:root {
  --theme-color: #000000;
  --pageWidth: 50%;
}

body {
  font-size: 1rem;
  line-height: 1.1;
  color: #444444;
  font-family: "Lato", Roboto, "Helvetica Neue", Arial, sans-serif;
  overscroll-behavior-y: contain;
}
html {
  overflow: hidden;
  overscroll-behavior: none;
}
body > iframe {
  pointer-events: none;
}
a {
  outline: 0 !important;
  text-decoration: none !important;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

.icon {
  fill: currentColor;
  display: inline-block;
  user-select: none;
}

.btn {
  box-shadow: none;
  border: 0;
  outline: 0 !important;
  background: var(--theme-color);
  padding: 8px 12px;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  border: 1px solid transparent;
  transition: 0.35 ease-out;
}

.btnCustomLink {
  box-shadow: none;
  border: 0;
  outline: 0 !important;
  background: #ffffff;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: 0.35 ease-out;
  border-color: var(--theme-color);
  color: var(--theme-color);
  margin-right: 3.6rem;
}

.btnCustomLink:hover,
.btnCustomLink:active {
  color: #ffffff;
  background: var(--theme-color);
}

.customLinkSpace {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.banner {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100dvh;
  overflow: hidden;
  background-color: var(--theme-color);
}
img{
  -webkit-user-select: none;
}
.hideBanner {
  transform: translateY(-100%);
  transition: all 0.5s ease-in-out;
}

.btn:hover,
.btn:active {
  border-color: var(--theme-color);
  color: var(--theme-color);
  background: #ffffff;
}

.btn + .btn {
  margin-left: 15px;
}

.text-center {
  text-align: center;
}

.bg-white {
  background: #ffffff;
}
.bg-transparent {
  background: transparent !important;
}
.wrap {
  width: 100%;
  height: 100%;
  position: relative;
}

.clearfix,
.clearfix::after,
.clearfix::before {
  display: table;
  width: 100%;
  clear: both;
}

.no-scroll .wrap {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

#wrapper {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  background: #f1f1f1;
  position: fixed;
}

.app-overlay {
  position: fixed;
  max-width: 100%;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: var(--theme-color);
  z-index: 9999999998;
}

.app-overlay.in {
  opacity: 1;
  display: block;
}

.app-overlay.inner-overlay {
  opacity: 0.7;
  z-index: 999999;
}

.app-overlay.animate {
  opacity: 0;
  animation: Loadin 1800ms ease;
  -webkit-animation: Loadin 1800ms ease;
  -moz-animation: Loadin 1800ms ease;
}

.app-overlay.out {
  opacity: 0;
  display: none;
}

.app-overlay .logowrap {
  display: block;
  max-width: 160px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0 auto;
  left: 0;
  right: 0;
}

.banner .creator_credits {
  display: inline-block;
  padding: 5px 8px;
  background: #f1f1f1;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  max-width: 130px;
  font-size: 10px;
  line-height: 1;
  text-align-last: center;
  border-radius: 8px 8px 0px 0px;
  user-select: none;
}

.banner .creator_credits:hover {
  opacity: 1;
}

.banner .creator_credits a {
  vertical-align: top;
  line-height: 1;
}

.banner .creator_credits img {
  max-height: 12px;
  margin-top: -4px;
  margin-left: 1px;
  vertical-align: middle;
  line-height: 1;
}

.layout {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.layout.scroll {
  overflow-y: auto;
  max-height: none;
  padding: 50px 0;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.empty-wrapper {
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .row::after, .row::before {
	content: '';
	clear: both;
	width: 100%;
	display: table;
} */

.column {
  float: left;
  width: 100%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.site-header {
  background: var(--theme-color);
  height: 50px;
  line-height: 1;
  position: relative;
  z-index: 1;
}

.layout.scroll .site-header {
  position: fixed;
  z-index: 1;
  top: 0;
  width: 100%;
}

.site-header .nav-btn {
  color: #ffffff;
  text-align: center;
  transition: 0.2s;
}

.site-header .nav-btn:hover {
  background: #ffffff;
  color: var(--theme-color);
}

.site-header .nav-btn a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px 5px;
}

.site-header .nav-btn .downdload-btn {
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px 5px;
  background-color: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  color: inherit;
  font-size: 13px;
}

.site-header .nav-btn.clickable {
  padding: 10px 5px;
  cursor: pointer;
  position: relative;
}

.site-header .cart-counter {
  position: absolute;
  top: 4px;
  left: 17px;
  width: 20px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
}

.nav-logo {
  float: left;
}

.nav-logo img {
  max-height: 40px;
}

.nav-logo a,
.isNOtShowHome {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 5px 15px;
}

.center-nav {
  display: block;
  width: 350px;
  height: 50px;
  margin: 0 auto;
  text-align: center;
}

.center-nav > span,
.right-nav > span {
  width: 50px;
  height: 50px;
  display: inline-block;
  vertical-align: top;
}

.newCenterNav > span {
  width: 120px;
  height: 50px;
  display: inline-block;
  vertical-align: top;
}

.center-nav .goto-Handler {
  background: var(--theme-color);
  width: 100px;
  overflow: hidden;
  user-select: none;
}

.center-nav .goto-Handler input {
  height: 50px;
  width: 100px;
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
  margin: 0;
  text-align: center;
  padding: 10px;
  background: var(--theme-color);
  font-size: 12px;
  user-select: none;
  color: #ffffff;
}

.center-nav .goto-Handler input::placeholder {
  color: #ffffff;
}

.right-nav {
  width: auto;
  height: 50px;
  float: right;
}

.newRightNavAddCss {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  float: none;
}

.right-nav .toggle-sidebar {
  padding: 0 !important;
  overflow: hidden;
}

.right-nav .toggle-sidebar > span {
  display: block;
  width: 100px;
  height: 50px;
  transition: 0.4s ease-out;
  left: -50px;
  position: relative;
}

.right-nav .toggle-sidebar > span.enabled {
  left: 0px;
}

.right-nav .toggle-sidebar > span > span {
  padding: 10px 5px;
  display: inline-block;
  width: 50px;
  height: 50px;
}

.pdf-item {
  cursor: pointer;
  margin: 40px 0px 0px;
  text-decoration: none !important;
  background: #ffffff;
  display: block;
  user-select: none;
  transition: 0.6s ease;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.2);
}

.pdf-item:hover {
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.4);
}

.pdf-title {
  padding: 12px 8px;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdf-title h2 {
  text-align: center;
  font-size: 18px;
  line-height: 1.1;
  font-weight: normal;
  margin: 0;
}

.e-viewer {
  width: 100%;
  height: calc(100% - 28px);
  line-height: 1;
  max-height: 100%;
  max-width: 100vw;
  overflow: hidden;
  position: relative;
}

.loader {
  position: absolute;
  /* background: #ffffff url('/images/ECOL_LOGO.gif') no-repeat center center; */
  background: #ffffff no-repeat center center;
  background-size: 100px;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9959;
}

.slider-container {
  height: 100%;
  margin-right: auto;
  /* overflow: hidden; */
}

.slider-wrap-embed {
  margin: 0 !important;
}
.slider-wrap {
  margin: 0 auto;
  position: relative;
  height: 100%;
}

.slider {
  margin: 0 auto;
  position: relative;
  /* overflow: hidden; */
}

.slider.zoom-enabled {
  overflow: visible;
}

.slide-stagger {
  width: 300%;
  height: 100%;
  user-select: none;
}

.slide-stagger > div {
  width: 100%;
  height: 100%;
  z-index: 6;
  user-select: none;
}

.ecollat-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  user-select: none;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.spread-page .ecollat-shadow::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  box-shadow: -5px 0 9px 0 rgba(0, 0, 0, 0.1);
}

.spread-page .ecollat-shadow::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  right: 0;
  top: 0;
  bottom: 0;
  box-shadow: 5px 0 9px 0 rgba(0, 0, 0, 0.1);
}

.spread-page.init-page .ecollat-shadow::before {
  opacity: 0;
}

.spread-page.end-page .ecollat-shadow::after {
  opacity: 0;
}

.single-page .ecollat-shadow::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  box-shadow: 0px 0 9px 0 rgba(0, 0, 0, 0.1);
}

.navigation {
  width: 55px;
  position: absolute;
  height: 100%;
  top: 0;
  padding-top: inherit;
  color: var(--theme-color);
}

.navigation.left-nav {
  left: 0;
}

.navigation.right-nav {
  right: 0;
}

.navigation .nav-end {
  height: 30px;
  width: 30px;
  display: block;
  margin: 0 auto;
}

.navigation .nav-navigate {
  height: 35px;
  width: 35px;
  display: block;
  margin: 0 auto;
  left: 0;
  right: 0;
  position: absolute;
  top: calc(50% - 17px);
}

.navigation .nav-end a,
.navigation .nav-end a svg,
.navigation .nav-navigate .link,
.navigation .nav-navigate .link svg {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: inline-block;
}

.slide-stagger .slide-item {
  float: left;
  height: 100%;
  position: relative;
  /* transition: 0.4s ease; */
  cursor: zoom-in;
}

.slider.zoom-enabled .slide-item:nth-child(1),
.slider.zoom-enabled .slide-item:nth-child(3) {
  visibility: hidden;
}

.slide-item .image-item {
  width: 100%;
  max-width: 100%;
}

.slide-item .slide-single {
  width: 100%;
  height: 100%;
}

.slide-item .slide-spread {
  max-width: 50%;
  position: absolute;
  top: 0;
}

.slide-item .slide-spread.left {
  left: 0;
}

.slide-item .slide-spread.right {
  right: 0;
}

.element-pan .onlyForFirstPageRight {
  right: 15% !important;
}

.hot-area {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.hot-area .hot-spot {
  position: absolute;
  cursor: pointer;
  z-index: 9;
}

.slide-stagger
  > div
  > .slide-item:nth-child(2)
  .hot-area
  .hot-spot.type-product {
  /* animation: 2s BounceUp .4s;
	-webkit-animation: 2s BounceUp .4s;
	-moz-animation: 2s BounceUp .4s;
	-ms-animation: 2s BounceUp .4s;
	animation-delay: .4s; */
  transform: translateY(0) !important;
  transition: 1.5s all ease;
}

.hot-area .hot-spot.type-search {
  background: rgba(94, 185, 249, 0.4);
}

.hot-area .hot-spot a,
.hot-area .hot-spot span {
  display: block;
  width: 100%;
  height: 100%;
}

/* Button Size Css */

/* For Single Page */
.hot-area .hot-spot.icon-small.type-product.single-page {
  width: 2% !important;
}

.hot-area .hot-spot.icon-medium.type-product.single-page {
  width: 2.5% !important;
}

.hot-area .hot-spot.icon-large.type-product.single-page {
  width: 3.5% !important;
}

/* For Double Page */
.hot-area .hot-spot.icon-small.type-product {
  width: 4% !important;
  /* min-width: 15px !important;
	min-height: 15px !important; */
  /* width: calc(105 / 1653 * var(--pageWidth)) px  !important;
  	height: calc(105 / 1653 * var(--pageWidth)) px !important; */
}

.hot-area .hot-spot.icon-medium.type-product {
  width: 5% !important;
  /* min-width: 20px !important;
	min-height: 20px !important; */
  /* width: calc(157 / 1653 * var(--pageWidth)) px !important;
	height: calc(157 / 1653 * var(--pageWidth)) px !important; */
}

.hot-area .hot-spot.icon-large.type-product {
  width: 7% !important;
  /* min-width: 25px !important;
	min-height: 25px !important; */
  /* width: calc(210 / 1653 * var(--pageWidth)) px !important;
  	height: calc(210 / 1653 * var(--pageWidth)) px  !important; */
}

.ico,
.clear-item {
  width: 30px;
  height: 30px;
  display: block;
  margin: 0 auto;
}

/* -- Button Size Css --  */

/* Button theme */

.hot-area .hot-spot.dark-theme svg,
.hot-area .hot-spot.dark-theme img {
  filter: invert(0);
}

.hot-area .hot-spot.light-theme svg,
.hot-area .hot-spot.light-theme img {
  /* color: #252525 !important; */
  border-radius: 100%;
  filter: invert(1);
}

/* -- Button theme --  */

.slide-stagger > div > .slide-item:nth-child(2) .hot-spot.type-pageLink.no-icon,
.slide-stagger > div > .slide-item:nth-child(2) .hot-spot.type-link.no-icon,
.slide-stagger > div > .slide-item:nth-child(2) .hot-spot.type-iframe.no-icon {
  /* animation: 2s Highlight 0.4s;
  -webkit-animation: 2s Highlight 0.4s;
  -moz-animation: 2s Highlight 0.4s;
  -ms-animation: 2s Highlight 0.4s; */
  animation-delay: 0.4s;
}
.type-carousel {
  max-width: 100% !important;
}

.highlight {
  animation: highlight 1.5s ease-in-out;
  background-color: transparent;
  animation-delay: 0.5s;
}

@keyframes highlight {
  0% {
    background-color: rgba(
      94,
      184,
      249,
      0.253
    ); /* Transparent blue at the start */
  }
  100% {
    background-color: transparent; /* Back to fully transparent at the end */
  }
}

/* ---------- App Sidebar Style -------------- */

.app-sidebar {
  width: 370px;
  /* height: 100%; */
  height: calc(100% - 50px);
  position: absolute;
  top: 50px;
  bottom: 0;
  right: 0;
  background: var(--theme-color);
  z-index: 5678;
  transition: 0.55s ease;
  -webkit-transition: 0.55s ease;
  transform: translateX(480px);
  -webkit-transform: translateX(480px);
}

.app-sidebar.open {
  transform: translateX(0px);
  -webkit-transform: translateX(0px);
}

.accordion {
  color: #f1f1f1;
  list-style: none;
  padding: 0;
  margin: 0px 15px;
  padding-top: 15px;
}

.accordion ul,
.accordion li {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  width: 100%;
  position: relative;
}

.accordion li:hover > span:not(.accordion-arrow, .accordion-subarrow) {
  transform: translateX(10px);
}

.accordion > li span {
  display: inline-block;
  width: 100%;
  padding: 10px 12px;
  position: relative;
  line-height: 1;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.6s ease;
}

.accordion-child,
.accordion-sub-child {
  overflow: hidden;
  height: 0px;
  transition: 0.4s ease;
}

.accordion-child li span,
.accordion-sub-child li span {
  font-size: 14px;
  font-weight: normal;
}

.hasChild .accordion-arrow,
.hassubChild .accordion-subarrow {
  width: 30px;
  display: inline-block;
  padding: 5px;
  top: 3px;
  right: 15px;
  position: absolute;
  transition: 0.4s ease;
}

.hasChild .title {
  padding-right: 50px;
}

.hasChild .accordion-arrow svg,
.hassubChild .accordion-subarrow svg {
  width: 20px;
  height: 20px;
}

.hasChild.open .accordion-arrow,
.hassubChild.open .accordion-subarrow {
  transform: rotateZ(90deg);
}

.hasChild.open .accordion-child,
.hassubChild.open .accordion-sub-child {
  height: 100%;
}

/* --------~//-- App Sidebar Style ------~//~-------- */

.ecollat-search {
  height: 50px;
  min-width: 50px;
  position: relative;
  width: auto;
  display: inline-block;
  background: #ffffff;
}

.ecollat-search div {
  width: 50px;
  transition: width 0.4s ease;
}

.ecollat-search div.search-close {
  width: 50px;
}

.ecollat-search div.search-open {
  width: 320px;
}

.ecollat-search div span {
  color: #ffffff;
  background: var(--theme-color);
  width: 50px;
  height: 50px;
  position: absolute;
  padding: 10px 5px;
  cursor: pointer;
  text-align: center;
  z-index: 4;
}

.ecollat-search div.search-open span,
.ecollat-search div span:hover {
  background: #ffffff;
  color: var(--theme-color);
}

.ecollat-search div .close-search {
  right: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease 0.4s;
}

.ecollat-search div .open-search {
  left: 0;
}

.ecollat-search div.search-open .close-search {
  opacity: 1;
  pointer-events: all;
}

.ecollat-search .close-search.is-loading {
  pointer-events: none !important;
  background: url("../../../public/images/search-loading.gif") no-repeat;
  background-size: 75%;
  background-position: 6px 5px;
}

.ecollat-search .close-search.is-loading .icon {
  display: none;
}

.ecollat-search div input {
  width: 0px;
  height: 50px;
  position: absolute;
  border: 0px;
  outline: 0;
}

.ecollat-search div.search-open input {
  width: 100%;
  padding: 10px 50px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
}

.element-pan {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}

.element-pan .slide-item {
  position: relative;
  cursor: zoom-out;
}

.element-pan .slide-item:active {
  cursor: grabbing;
}

.element-pan .slide-item .slide-spread {
  max-width: none !important;
}

/* --------- Search Result Style ------------ */

.search-slider {
  height: calc(100% - 50px);
  max-height: calc(100% - 50px);
  width: 100%;
  display: block;
  float: left;
  max-width: 370px;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 50px;
  z-index: 50;
  transition: transform 0.4s ease;
  background: #ffffff;
  box-shadow: -1px 14px 15px -1px rgba(0, 0, 0, 0.25);
  transform: translateX(480px);
}

.search-slider.show {
  transform: translateX(0px);
}

.search-results {
  padding: 20px 15px;
  text-align: center;
  display: grid;
  grid-template-columns: auto auto;
  gap: 2rem;
}

/* .result-item+.result-item {
	margin-top: 30px;
} */

.result-item.spread a {
  display: block;
  width: 100%;
  height: 100%;
}

.result-item.spread a img {
  max-width: 50%;
  display: inline-block;
}

/* ----~//~----- Search Result Style ------~//~------ */

.bottom-navigation {
  width: 100%;
  position: fixed;
  bottom: 10px;
  right: 0px;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-around;
}
.bottom-Embeded {
  position: unset !important;
}

.bottom-navigation.videoAdded {
  bottom: 50px;
}

.bottom-navigation li {
  display: block;
  cursor: pointer;
}

.bottom-navigation li span {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  vertical-align: middle;
  border-radius: 50%;
  color: #f1f1f1;
  background: var(--theme-color);
  text-align: center;
  padding: 4px 0px;
}

.bottom-navigation li span .icon {
  width: 28px;
  height: 28px;
}

.bottom-navigation li span button {
  border: 0;
  background: transparent;
  cursor: pointer;
  outline: none;
  box-shadow: none;
}

.bottom-navigation li span button .icon {
  fill: #fff;
}

.bottom-navigation li span i {
  font-style: normal;
}

.layout > .bottom-navigation li span i {
  font-style: normal;
  display: none;
}

.epaper-custompdf {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 50px;
  width: 100vw;
  height: calc(100% - 50px);
  background: #ffffff;
  z-index: 7;
  animation: FadeIn 0.5s;
  -webkit-animation: FadeIn 0.5s;
  -moz-animation: FadeIn 0.5s;
  -ms-animation: FadeIn 0.5s;
  overflow: hidden;
}

.pdf-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  /* padding-top: 10px; */
  padding-bottom: 10px;
}

.page-selector {
  height: 100%;
  max-height: 100%;
  overflow: auto;
  width: calc(100% - 320px);
  float: left;
  text-align: center;
}

.page-thumb {
  display: inline-block;
  margin-bottom: 20px;
  border: 1px solid #8c8c8c;
  width: 172px;
  overflow: hidden;
  position: relative;
}
.page-thumb-generate {
  margin-right: unset !important;
}
.page-thumb-width {
  width: 234px !important;
}
.page-thumb img {
  max-width: 100%;
  margin: 0 auto;
}
.Slide-view {
  /* border: 1px solid #8c8c8c45 !important; */
  width: 100% !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.page-thumb.page-thumb {
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.page-thumb .thumb-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: -100%;
  left: 0;
  right: 0;
  display: inline-block;
  transition: bottom 0.4s ease;
}

.page-thumb:not(.has-touch) img:hover ~ .thumb-overlay,
.page-thumb:not(.has-touch) .thumb-overlay:hover,
.page-thumb.added .thumb-overlay,
.page-thumb.has-touch img:focus ~ .thumb-overlay {
  bottom: 0;
}

.page-thumb .thumb-overlay .overlay-bg {
  width: 100%;
  height: 100%;
  background: var(--theme-color);
  opacity: 0.65;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.page-thumb .thumb-overlay .add-page {
  width: 50px;
  height: 50px;
  display: inline-block;
  position: relative;
  z-index: 5;
  top: calc(50% - 20px);
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0px 1px 13px 0px rgba(0, 0, 0, 0.3);
  opacity: 0.8;
  transition: opacity 0.4s ease;
}

.page-thumb .thumb-overlay .add-page svg {
  width: 100%;
  height: 100%;
  transition: transform 0.2s ease-in;
  position: relative;
  z-index: -1;
}

.page-thumb.added .thumb-overlay .add-page svg {
  transform: rotateZ(-45deg);
}

.page-thumb .thumb-overlay .add-page:hover {
  opacity: 1;
}

.selected-page-wrapper {
  height: 95%;
  overflow: auto;
  padding: 15px;
}

.btn-container {
  height: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.genrate-btn {
  display: none;
}

.btn-container .genrate-btn {
  position: static;
  display: block;
}

.pdf-selected-sidebar {
  width: 320px;
  height: 100%;
  /* max-height: calc(100% - 52px); */
  /* overflow: auto; */
  background: #ffffff;
  float: left;
  padding: 15px 0;
}

.pdf-selected-sidebar .page-thumb.sidebarshown {
  display: inline-block;
  width: 50%;
  margin-bottom: 30px;
  border: 0px;
  margin-left: 0;
  text-align: center;
}

.pdf-selected-sidebar .page-thumb:first-child {
  display: block;
  margin-left: 50%;
}

.pdf-selected-sidebar .page-thumb img {
  max-width: 100%;
  display: block;
}

.pdf-selected-sidebar .page-thumb.sidebarshown .thumb-overlay .add-page {
  width: 30px;
  height: 30px;
  top: calc(50% - 15px);
}

.selpfd-title {
  height: 50px;
  padding: 8px 12px;
  color: #f1f1f1;
  display: inline-block;
  vertical-align: top;
  line-height: 35px;
  font-size: 18px;
}

.genrate-btn {
  position: absolute;
  bottom: 0;
  width: auto;
  padding: 10px 16px;
  background: var(--theme-color);
  color: #f1f1f1;
  right: 100px;
  bottom: 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 18px;
  z-index: 10;
  /* height: 40px; */
}

.genrate-btn.disabled {
  filter: invert(15%);
  cursor: not-allowed;
  pointer-events: none;
  user-select: none;
}

.genrate-btn span {
  display: inline-block;
  vertical-align: middle;
}

.genrate-btn svg {
  width: 25px;
  height: 25px;
  vertical-align: text-bottom;
}

.close-selectedpdfbar {
  width: 30px;
  height: 30px;
  background: var(--theme-color);
  color: #f1f1f1;
  display: block;
  position: fixed;
  z-index: 55;
  border-radius: 50%;
  padding: 6px 6px;
  left: 10px;
  top: 60px;
  display: none;
}

.close-selectedpdfbar svg {
  width: 100%;
  height: 100%;
}

.social-share-ico .nav-btn {
  margin-right: 10px;
  border-radius: 5px;
}

.scrollable-list {
  height: 100%;
  overflow: auto;
}

.scrollable-list div {
  margin-bottom: 10px;
}

.bottom-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  padding: 5px 10px;
  display: none;
  z-index: -1;
  transition: z-index 0.1s linear 0.3s;
}

.popup-wrap {
  z-index: 999999999999;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup {
  max-height: 100%;
  min-height: 350px;
  /* overflow: scroll; */
  background: #ffffff;
  max-width: 650px;
  width: 90%;
  padding: 10px 30px 25px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 4px;
  z-index: 5;
  animation: FlipIn 400ms ease-in;
}

.popup.type-howItWorks {
  background-color: transparent;
  max-width: 869px;
  padding: 0;
  outline: none !important;
}
.popup.type-cartDownload {
  overflow: auto;
}
.popup.type-addProductDetails {
  /* background-color: transparent; */
  max-width: 800px;
  /* padding: 0; */
  /* outline: none !important; */
}
.popup.type-howItWorks video {
  outline: none !important;
}

.popup.type-howItWorks .close-popup {
  right: 0;
  top: -30px;
  z-index: 2;
  background-color: #fff;
  border-radius: 0;
}

.popup.type-howItWorks .icon {
  fill: #000;
}

.popup.type-iframe {
  padding: 0;
  min-width: 1200px !important;
  min-height: 80vh;
}

.popup.type-iframe iframe {
  width: 100% !important;
  height: calc(100% + 1px) !important;
  max-width: 1600px !important;
  max-height: 1200px !important;
  margin: 0 auto;
  padding: 0;
  resize: none;
  border: 0;
  position: relative;
}

.popup.type-iframe .popup-body {
  width: 100%;
  height: 100%;
  position: absolute;
}

.popup.type-iframe .close-popup {
  right: -12px;
  top: -12px;
  z-index: 999;
}

.popup-title {
  width: 100%;
  margin-bottom: 25px;
}

.popup-title h2 {
  font-size: 26px;
  margin: 0px;
  padding: 10px 0px;
  color: var(--theme-color);
  border-color: var(--theme-color);
}

.popup .close-popup {
  width: 30px;
  height: 30px;
  position: absolute;
  background: #ffffff;
  border-radius: 50%;
  right: 25px;
  top: 18px;
  cursor: pointer;
  line-height: 1;
  z-index: 1111;
}

.popup .close-popup svg {
  width: 30px;
  height: 30px;
  color: #8a8a8a;
}

.popup .close-popup svg:hover {
  color: var(--theme-color);
}

.has-input input {
  height: 40px;
  outline: 0;
  line-height: 1;
  border: 2px solid;
  padding-left: 15px;
  padding-right: 15px;
  border-color: var(--theme-color);
  width: 100%;
}

.has-input {
  vertical-align: top;
  margin-bottom: 25px;
}

.has-input.has-btn input {
  border-right: 0px;
  max-width: calc(100% - 150px);
}

.has-input .input-btn {
  height: 40px;
  padding: 0px 20px;
  background: var(--theme-color);
  outline: 0;
  box-shadow: none;
  border: 0px;
  color: #ffffff;
  line-height: 35px;
  vertical-align: top;
  cursor: pointer;
}

.has-input .input-btn span {
  vertical-align: middle;
}

.has-input .input-btn .btn-icon svg {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  line-height: 1;
  margin-top: -5px;
}

.social-share-ico .nav-btn {
  background: #f1f1f1;
  height: 45px;
  width: 45px;
  display: inline-block;
  padding: 7px;
  cursor: pointer;
}

.product-drawer {
  position: fixed;
  width: 100%;
  /* max-width: 960px; */
  max-width: 1150px;
  top: 50px;
  right: 0;
  height: calc(100vh - 50px);
  overflow: auto;
  background: #fbfbfb;
  transition: 0.4s ease;
  transform: translateX(110%);
  z-index: 999999;
  box-shadow: -1px 14px 15px -1px rgba(0, 0, 0, 0.25);
  /* padding: 2% 1% 0 1%; */
}

.product-drawer.is-open {
  transform: translateX(0);
}

.cart-item {
  padding: 8px 15px;
  margin: 10px auto;
  background: #ffffff;
}

.cart-item .clear-item {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 15px;
  cursor: pointer;
}

.cart-item .clear-item svg {
  width: 20px;
  height: 20px;
  color: var(--theme-color);
}

.cart-item h2 {
  font-size: 17px;
}

.cart-item .item-img {
  max-width: 100px;
}

.cart-item .item-img img {
  border-radius: 4px;
}

.cart-item .item-desc {
  width: calc(100% - 100px);
  font-size: 14px;
}

.cart-item .item-desc p {
  margin-bottom: 5px;
}

.cart-item .item-desc .item-quanity {
  max-width: 50px;
  height: 22px;
  outline: 0 !important;
  margin-right: 10px;
  text-align: center;
}

.cart-item .item-desc .price {
  height: 22px;
  line-height: 1;
}

.cartData-action {
  padding: 15px;
  text-align: center;
}

.product-downloadables {
  width: 100%;
  overflow-x: auto;
  list-style: none;
  padding: 0;
  margin: 0;
}

.product-downloadables table {
  width: 590px;
}

.product-downloadables li {
  display: block;
  margin-bottom: 7px;
}

.product-downloadables li a {
  display: inline-block;
  line-height: 20px;
  vertical-align: middle;
}

.product-downloadables li span {
  display: inline-block;
}

.product-downloadables li span.count {
  margin-right: 5px;
}

.product-downloadables li span.ico {
  width: 20px;
  height: 20px;
  margin-left: 12px;
  vertical-align: middle;
}

.product-downloadables li span.ico svg {
  width: 20px;
  height: 20px;
}

.howitworks-icon {
  width: 38px;
  height: 38px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  color: #f1f1f1;
  background: var(--theme-color);
  text-align: center;
  padding: 4px 0px;
  margin-right: 10px;
}

.goto-howitworks {
  position: fixed;
  bottom: 10px;
  right: 30px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.goto-howitworks a {
  font-size: 16px;
}

.pwa-progressbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 100vw;
  height: 7px;
  background: #d4d4d4;
  z-index: 999999;
}

.pwa-progressbar .pwa-install-progress {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: var(--theme-color);
}

.pwa-progressbar .pwa-install-progress.pwa-error {
  background-color: #ff3939;
}

/* accordion */

.search-accordion {
  margin: 10px 0;
}

.search-accordion .head {
  width: 90%;
  margin: 0 auto;
  color: #000;
  padding: 15px 15px;
  font-size: 20px;
  position: relative;
  padding-right: 5%;
  cursor: pointer;
  box-shadow: 0 3px 6px rgb(187 178 178 / 16%),
    -1px 1px 10px rgba(0, 0, 0, 0.23);
}

.search-accordion .head span {
  display: inline-block;
  font-size: 18px;
}

.search-accordion .body {
  max-height: 0;
  height: auto;
  overflow: hidden;
  transition: max-height 1s ease;
}

.search-accordion .head .arrow-icon {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 5%;
  transform: rotate(90deg);
  transition: 0.5s all ease;
}

.search-accordion .head .arrow-icon.active {
  transform: rotate(-90deg);
}

.body.active {
  max-height: 3000px;
}

/* Cart Table */
.displayNoneMobile {
  display: block;
  /* height: calc(100vh - 183px);
	overflow-y: auto; */
}

.mobileCartsection,
.mobileCartsectionContainer,
.mobileCartsectionContainerCollection {
  display: none;
}

.cart-table {
  padding: 19px;
  width: 100%;
  overflow: auto;
  text-align: left;
}

.cart-table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 960px;
}

.cart-table p {
  margin: 0;
}

.cart-table table input {
  width: 100%;
  text-align: center;
}

/* .cart-table table th:nth-child(1) {
	width: 11%;
}

.cart-table table th:nth-child(2) {
	width: 13%;
}
.cart-table table th:nth-child(3) {
	width: 14%;
}

.cart-table table th:nth-child(4) {
	width: 11%;
}

.cart-table table th:nth-child(5) {
	width: 25%;
}

.cart-table table th:nth-child(6) {
	width: 8%;
}

.cart-table table th:nth-child(7) {
	width: 15%;
}
.cart-table table th:nth-child(8) {
	width: 4%;
} */

.productQuantityMain {
  border: 1px solid #929292;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productQuantityMain p {
  padding: 0.5rem 0.7rem;
  margin-bottom: 0;
  font-size: 1.2rem;
  cursor: pointer;
  height: 2.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cartRemoveImgDiv {
  width: 2.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cartRemoveImgDiv img {
  max-width: 100%;
  height: auto;
}

.minusFont {
  font-size: 1.4rem !important;
}

.productQuantityMain p:nth-child(2) {
  border-left: 1px solid #929292;
  border-right: 1px solid #929292;
  font-size: 1rem;
}

/* Modal */
.modal {
  width: 90%;
  /* max-width: 900px; */
  max-width: 1000px;
  height: 60vh;
  position: absolute;
  overflow-y: auto;
  background-color: #fbfbfb;
  z-index: 999999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 1%;
}

/* .forOnlyMobileAddToCart {
	width: 95%;
	height: auto;
} */

.modal .cross {
  display: block;
  cursor: pointer;
  position: relative;
  width: 35px;
  height: 35px;
  margin: 0px 0px 0px auto;
}

.modal .cross:before {
  display: inline-block;
  position: absolute;
  width: 2px;
  height: 20px;
  content: " ";
  background-color: #000;
  left: 52%;
  top: 48%;
  transform: translate(-50%, -50%) rotate(40deg);
}

.modal .cross:after {
  position: absolute;
  display: inline-block;
  content: " ";
  height: 20px;
  width: 2px;
  left: 30%;
  top: 0%;
  background-color: #000;
  transform: rotate(140deg) translate(-50%, -47%);
}

.multi-products {
  width: 100%;
  overflow-x: auto;
}

.multi-products table {
  width: 900px;
}

.multi-products table th:nth-child(1) {
  width: 12%;
}

.multi-products table th:nth-child(2) {
  width: 12%;
}

.multi-products table th:nth-child(3) {
  width: 30%;
}

.multi-products table th:nth-child(4) {
  width: 6%;
}

.multi-products table th:nth-child(5) {
  width: 15%;
}

.multi-products table th:nth-child(6) {
  width: 15%;
}

.multi-products table .add-cart {
  display: block;
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 8px 0px;
  font-size: 13px;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--theme-color);
  color: #fff;
}

.add-cart-collection,
.add-cart-collection:focus,
.add-cart-collection:active {
  display: block;
  /* width: 122px; */

  border: none;
  outline: none;
  box-shadow: none;
  padding: 8px 16px;
  font-size: 13px;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--theme-color);
  color: #fff;
}

.MobilePricePerUnit {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  /* justify-content: space-between; */
}

.MobilePricePerUnit h3 {
  margin-bottom: 0;
  font-size: 14px;
}

.MobilePricePerUnit p {
  margin-bottom: 0;
  margin-left: 1rem;
}

.collectionNameAndPrice,
.collectionDescAndAddCart {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.collectionNameAndPrice h5 {
  min-width: 122px;
  padding: 8px 0px;
  text-align: center;
  font-size: 16px;
}

.collectionNameAndPrice h4 {
  font-size: 16px;
}

.collectionProducts {
  margin-bottom: 1rem;
}

.collectionProducts h4 {
  font-size: 17px;
}

.collectionDesc {
  max-width: 70%;
}

.overlay-banner-logo {
  max-width: 300px;
  height: auto;
}

.empty-cart-text {
  margin: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 90px);
  font-size: 25px;
  justify-content: center;
  text-transform: capitalize;
}

.toast-style {
  background-color: var(--theme-color);
}

.toast-progress-style {
  background-color: #fff;
}

.close-cart-icon {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: 98%;
  top: 2%;
  cursor: pointer;
}

.close-cart-icon svg {
  width: 30px;
  height: 30px;
}

/* Download PDF Form */
.popup-content .download-pdf-form {
  text-align: center;
  width: 75%;
  margin: 0 auto;
}

.mandatory-field {
  color: #ff3939;
  margin-left: -5px;
}

.popup-content .download-pdf-form .form-group {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  text-align: left;
  width: 100%;
}

.popup-content .download-pdf-form label {
  font-size: 1.2rem;
  margin-bottom: 0;
  margin-right: 15px;
  width: 25%;
}

.popup-content .download-pdf-form input,
.popup-content .download-pdf-form textarea,
.popup-content .download-pdf-form select {
  border: 0 !important;
  border-bottom: 1px solid #000 !important;
  height: 40px;
  outline: none !important;
  width: 70%;
  background-color: transparent;
}

/* .popup-content .download-pdf-form input:focus {
	border: 0 !important;
	border-bottom: 1px solid #000 !important;
} */

.popup-content .download-pdf-form .submit {
  padding: 0.6rem 0.5rem 0.7rem;
  border: 0;
  background-color: #000;
  color: #fff;
  line-height: 1;
  cursor: pointer;
  outline: 0 !important;
  margin: 0 auto;
}

.popup-content .download-pdf-form .submit.disabled {
  background-color: #8c8c8c;
  cursor: default;
}

.download-pdf-form.cart-download {
  width: 90%;
}

.download-pdf-form.cart-download .submit {
  margin-top: 2rem;
}

/* Download PDF Form */

/* cart */
.cartSectionHeading {
  margin: 20px 0px;
  font-size: 25px;
  text-transform: capitalize;
  text-align: center;
}

/* cart */

.cursorPointer {
  cursor: pointer;
}

/* Mp4 Video Play Background Hari Om  */
.bgVideoPlayer {
  width: 100%;
  height: 100%;
  object-fit: cover;
  clip-path: inset(0.1px 0.1px);
}

.textDanger {
  color: red;
  text-align: left;
  margin-left: 9rem;
  margin-top: -0.8rem;
  font-size: 12px;
}

.textDanger1 {
  color: red;
  text-align: left;
  margin-left: 8rem;
  font-size: 12px;
  margin-top: -0.8rem;
}

.buttonSpinner {
  position: relative;
}

.spinnerStyle {
  width: 3rem;
  margin: 0 auto;
  margin-top: 2rem;
}

.spinnerBg {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: var(--theme-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 2rem;
}

.bt-spinner {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: transparent;
  border: 4px solid #fff;
  border-top-color: gray;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* .blinkTextOnNoIcon {
	opacity: 0.3; */
/* animation: glowing 3s ease-in-out; */
/* background-color: #22ced6;
	-webkit-animation-duration: 5s;
	animation-duration: 5s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both; */
/* animation-name: fadeOut; */
/* } */
/* @keyframes fadeOut {
	0% {opacity: 0;}
	30% {opacity: 1;}
	50% {opacity: 0.5;}
	70% {opacity: 0.3;}
	100% {opacity: 0;}
 }

.blinkTextOnNoIcon:hover{
	background: #22ced6;
} */
/* Mp4 Video Play Background Hari Om  */

/* privacy and cookie */
.forPrivacyPolicyAndCookie {
  min-height: calc(100vh - 100px);
}

.privacyComMargintop,
.privacyComMargintop {
  min-height: calc(100vh - (130px + 2rem));
}

.layout.scroll {
  padding-bottom: 0;
}

.privacyAndCookieBottom {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.privacyAndCookieBottom a {
  font-size: 0.8rem;
}

.cookiePolicy {
  margin-left: 1rem;
}

.privacyComMargintop {
  margin-top: 2rem;
}

.forPrivacyPolicyMain {
  padding-bottom: 10px;
  margin-top: 0;
  padding-top: 0 !important;
}

.forPrivacyPolicyMain li {
  list-style: none;
  font-weight: normal !important;
}

.privacyCookieSidebarMain {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.socialMediaLinks {
  margin-bottom: 1rem;
}

/* privacy and cookie */

/* View Proposal Pdf start */
.pdfProposalAlign {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdfPageInfoViewProposal {
  margin-left: 1rem;
  margin-right: 1rem;
}

.viewPdfProposalBtnMain {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.viewpdfProposalMain {
  margin: auto;
}

.modal-backdrop.show {
  opacity: 0.7;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal {
  z-index: 99999999;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background: var(--theme-color);
  z-index: 9999999;
}

.confirmationModal {
  max-width: 400px !important;
  height: auto !important;
  z-index: 999999999;
}

.CloseConfirmModal {
  z-index: 99999999;
  width: 100% !important;
}

.confirmationActionBtnMain {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.bellowTextTotalMrp {
  margin-bottom: 0;
  font-size: 0.7rem;
  padding-top: 3px;
  color: #444444;
  font-weight: 400;
}

.CollectionTagParent {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0.5rem auto;
}

.CollectionTagMain {
  width: 200px;
}

/* .ClearCartBtn{
	background-color: #ff3939;
} */
/* View Proposal Pdf end */

.disAllowHomePagemain {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disAllowHomePageImgContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.disAllowHomePageImgContent h4 {
  font-size: 1.2rem;
}

.forOverFlowContactModalcontact {
  overflow: auto;
}

.contactModalMaxHeight {
  max-height: 50vh;
}
.forOverFlowContactModalcontact::-webkit-scrollbar {
  width: 0px;
}

.marginBottom10 {
  margin-bottom: 0.6rem;
}

/** ================================================= CSS by Anubhav Sharma =========================================================== */
/* .tooltip {
    position: absolute;
    background: rgba(0, 0, 0, 5.7);
    color: white;
    visibility: hidden;
    z-index: 11;
    right: 0;
    bottom: 0;
    padding: 8px;
    border-radius: 5px;
    border-radius: 5px;
  } */

.tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 5.7);
  color: white;
  z-index: 11;
  padding: 8px;
  border-radius: 5px;
  border-radius: 5px;
}

.loaderingpdf {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #22ced6;
  background-color: #000;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin-right: 8px; /* Adjust the margin as needed */
}

.loader-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Optional: Set the container height */
}
.loader:hover {
  border-color: black; /* Change border color on hover */
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* spiner */

.loadingSpinnerContainer {
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #ff943d transparent #ff943d transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* spiner */

/* Detail Product Button */
.product-image {
  cursor: pointer;
  filter: none !important;
}
/* .product-image:hover{
	height: auto;
    width: 3rem;
} */
.Centerbuttondiv {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  60% {
    -webkit-transform: translateY(-1px);
    -ms-transform: translateY(-1px);
    transform: translateY(-1px);
  }
}

.product-image:hover {
  -webkit-animation: bounce 1s ease-in-out;
  animation: bounce 1s ease-in-out;
}

.white-box {
  position: absolute;
  border-radius: 0.25rem;
  background: #fff;
  width: 13.75rem; /* Set the width you desire */
  height: auto;
  top: 0;
  right: -100px; /* Adjust the value to position the white box as desired */
}

.notshowonhover {
  cursor: pointer;
}

/* More info button */

.moreinfobutton {
  display: flex;
  padding: 0.5rem 1.375rem 0.5rem 1.375rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.5rem;
  border: 1px solid var(--border, #e6e6e6);
  background: #fdfdfd;
  color: #565656;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  cursor: pointer;
}

.moreinfobutton:hover {
  background-color: #d4d4d4;
}
.moreinfobutton:focus {
  outline: none;
}

/* Show div and button on div */

.showonhover {
  height: 100%;
  width: 100%;
  display: none;
}
.showonhover:hover {
  display: flex;
  background: rgba(255, 255, 255, 0.5);
}
.hoverdiv:hover .showonhover {
  display: flex;
  background: rgba(255, 255, 255, 0.5);
}

/* Tooltip */
.arrow_box {
  position: relative;
  margin: 20px;
  padding: 5px; /* Adjust the padding to make it smaller */
  background: #fff;
  border: 2px solid #fff; /* Adjust the border width */
}
.arrow_box h6 {
  margin: 0;
}
.arrow_box:after,
.arrow_box:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  /* border-color: rgba(136, 183, 213, 0); */
  border-top-color: #fff;
  border-width: 15px; /* Adjust the triangle size */
  margin-left: -15px; /* Adjust the triangle position */
}

.arrow_box:before {
  border-top-color: #fff;
  border-width: 18px; /* Adjust the triangle size */
  margin-left: -18px; /* Adjust the triangle position */
}

/* Product Details POPUP */
.mainproductdiv {
  /* padding: 1.5px; */
}

.Centerdiv .detaildiv {
  width: 40%;
}

.mainproductdiv .headdiv {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.close-popup-PD {
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  line-height: 1;
}
.mainproductdiv .Centerdiv {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

/* More image  */
.moreimagedivmain {
  width: 60%;
  gap: 1rem;
  display: flex;
  /* justify-content: space-evenly; */
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.moreimagedivmain .moreimagediv {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid var(--stroke, #e6e6e6);
  background: #fff;
}

.moreimagedivmain .imagediv img {
  height: 100%;
  width: 100%;
}

/* Details  */
.detaildiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
}
.detaildiv .title {
  /* margin-bottom: 1.5rem; */
}
.detaildiv .title h6 {
  margin: 0;
  padding: 0;
  color: var(--1, #1e293b);
  /* font-family: Inter; */
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.detaildiv .title span {
  color: var(--1, #1e293b);
  /* font-family: Inter; */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.detaildiv .discription {
  /* margin-bottom: 1.5rem; */
  color: #929292;
  /* font-family: Inter; */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.detaildiv .price {
  /* margin-bottom: 1.5rem; */
  color: var(--1, #753cb7);
  /* font-family: Inter; */
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: start;
}
.detaildiv .button {
  gap: 0.4rem;
  display: flex;
  flex-direction: column;
}
/* end */

.Primary-Button {
  outline: none;
  background: #753cb7;
  color: white;
  cursor: pointer;
  /* font-weight: 500; */
}
.Primary-Button:active {
  outline: none;
}
.Primary-Button:hover {
  background: #9a6ecc;
  font-weight: 500;
}
.Primary-Button:focus {
  background: #753cb7;
  outline: none;
  font-weight: 500;
}
.Secondary-Button {
  font-weight: 500;
  background: transparent;
  border-radius: 0.25rem;
  border: 1px solid #753cb7 !important;
  color: #753cb7;
}
.Primary-Button:disabled {
  background-color: #9a6ecc;
}

.medium-button {
  width: 100%;
  display: flex;
  font-size: 14px;
  height: 2.375rem;
  padding: 0rem 2rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: none;
  border-radius: 0.25rem;
}
.redamorebutton:focus {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}
.redamorebutton {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  color: var(--1, #21cfd6);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Product Detail Popup Tabbar */

.Tabbar {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.Tabbar .inactive {
  color: var(--text-box-Stroke, #929292);
  /* font-family: Inter; */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}
.Tabbar .active {
  color: var(--1, #753cb7);
  /* font-family: Inter; */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

/* Related products */
.Related-Products {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;
  justify-content: flex-start;
}
.left-arrow,
.right-arrow {
  display: flex;
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.25rem;
  border: 1px solid var(--stroke, #e6e6e6);
  background: #fff;
  cursor: pointer;
}
.productchildren {
  border-radius: 0.25rem;
  border: 1px solid var(--stroke, #e6e6e6);
  display: flex;
  width: 5rem;
  height: 5rem;
  justify-content: center;
  align-items: center;
}
.childrendiv {
  display: flex;
  gap: 1rem;
}

.slider-container {
  display: flex;
  align-items: center;
  justify-content: left;
}
.imagediv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.left-arrow,
.right-arrow {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  transition: background-color 0.3s ease;
}

.left-arrow:hover,
.right-arrow:hover {
  background-color: #ddd;
}

.childrendiv {
  display: flex;
  overflow: hidden;
}

.productchildren {
  width: 100px;
  height: 100px;
  min-width: 100px;
  /* margin: 0 ; */
  overflow: hidden;
  border: 1px solid #ccc;
}

.productchildren img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Moreimagediv {
  width: 5rem;
  height: 5rem;
  flex-shrink: 0;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.moreimagediv {
  height: 365px;
  overflow-y: auto;
  width: 130px;
}

/* Scroll bar CSS */

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 0.3125rem;
  background: #cacaca;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border-radius: 0.3125rem;
  background: #cacaca;
}
/* End */

/* Video play button  */

.play-pause-button {
  position: absolute;
  z-index: 1111111111;
  left: 50%;
  top: 50%;
  transform: translate(-21px, -11px);
}

.play-pause-button-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.play-pause-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

/* End  */

.flex-direction-coloum {
  flex-direction: column;
}

/* Embed CSS */
.Embed-bottom {
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  padding-right: 1rem;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.Embed-bottom button:active,
.Embed-bottom button:hover {
  outline: none;
  background-color: #d0d0d0;
}
.Embed-bottom button:focus {
  outline: none;
}
.Embed-bottom button {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAVpJREFUSA1jYBgFAx0CjOgOaGhoYJGUlMz4//+/NyMjoyi6PDY+UO1roNqtz58/nwHU/wdZDQsyB8QGGQ6kVH/8+JFQUFDwEl0eG3/ChAninJycVVC9U5DVMCFzQGyQy79//95GrOEgPSC1ID0gvSA+XjBz5swzMAXGxsb/YdjIyKgBJo6LRtYLU4PhA5gEMg102YRz5841IIsRyyZoAdDwBcAIjDU0NLQn1lBkdXgtABreCHR54r9//4yBlpgjaySWjZGKkDXCguX8+fMPgeJdyHLEsvH6gFhD8KkjywJgiioDxok8yGBCqYssC4Bxc5KJieks0PD5wLipp7oPgHFyEGjJYqDhCfgMB8mR5QNQsAANLyBkOFb5GTNmbAeVLVgl8QiC9ID0oivB8AHQZVtBBRcplsAKO5BedAsw8gGoyAWVihwcHAuAZQvRxTXQYHBxjW7BKH/gQwAAz8SBzjiHSiUAAAAASUVORK5CYII=);
  background-size: 24px 24px;
  margin-left: auto;
  cursor: pointer;
  background-color: #efefef;
  color: #333;
  font-weight: bold;
  padding: 0 8px 0 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-position: 4px 50%;
  background-repeat: no-repeat;
  border-radius: 3px;
  width: auto;
  border: none;
  height: 24px;
  font-family: inherit;
  font-size: inherit;
}

.Embed-bottom h1 {
  padding: 0;
  margin: 0;
}
/* END */

/* ======================Filp CSS======================== */
.flipbook {
  width: 100%;
  height: 100%;
}
/* ======================Filp END======================== */

.videoContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.bgVideoPlayer {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#myBtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
#myBtn2 {
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 1;
  right: 0;
  bottom: 0;
}

.Mobilerelative {
  overflow: auto !important;
  width: max-content;
}

/** ================================================= END by Anubhav Sharma =========================================================== */
/** ================================================= Header Version 2  by Anubhav Sharma =========================================================== */
.side-navigation {
  height: 100%;
  width: 3.5rem;
  display: block;
  float: left;
  overflow: auto;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 50;
  background: var(--theme-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.top-sidenav,
.middle-sidenav,
.bottom-sidenav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
}
.side-navigation span {
  display: flex;
  width: 100%;
  height: 2.5rem;
  padding: 0.26906rem 0.26269rem 0.23094rem 0.23731rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.bottom-sidenav .nav-btn .downdload-btn {
  padding: 0;
}
.bottom-sidenav .nav-btn:hover {
  /* background: (var(--theme-color)); */
  /* color: black; */
  cursor: pointer;
}
.side-navigation h5 {
  color: var(--text-box-Stroke, #929292);
  text-align: center;
  /* font-family: Inter; */
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  rotate: -90deg;
}
.side-navigation input {
  display: flex;
  width: 2.25rem;
  height: 1.5rem;
  color: white;
  padding: 0.5rem 0.1875rem 0.4375rem 0.1875rem;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 0.25rem;
  border: 1px solid #b1b1b1;
}
.side-navigation .cart-counter {
  position: absolute;
  /* top: 4px; */
  left: unset;
  right: 6px;
  width: 20px;
  top: -16px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
}
.Sidenav-product-drawer {
  height: 100% !important;
  top: 0;
}
/** ================================================= END by Anubhav Sharma =========================================================== */

/** ================================================= Flip book Css =========================================================== */

.page {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 400px;
  perspective: 3000px;
}

.default-position {
  transform: rotateY(0deg);
}

.right-img {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  perspective: 100px;
  transform-origin: left center;
  transition: transform 0.4s ease 0s;
}
.left-img {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  perspective: 100px;
  transform-origin: right center;
  transition: transform 0.5s ease 0s;
}

.left-cover {
  background-color: white;
  height: 100%;
  width: 50%;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.right-cover {
  background-color: white;
  height: 100%;
  width: 50%;
}
.front,
.back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}
.back .image-item {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container */
}
.front {
  z-index: 0;
}
.back {
  transform: rotateY(180deg);
}
.left-img {
  /* transform-origin: center;
  transform: rotateY(180deg); */
}

.stopshadow:before {
  box-shadow: none !important;
}

/* Override box-shadow for :after pseudo-element */
.stopshadow:after {
  box-shadow: none !important;
}

/* ============================ Carousal============================= */
.PopupCarousel {
  min-width: 300px;
  min-height: calc(100vh - 90px);
}

/* ============================ Zoom ============================= */

#zoom-controls {
  width: 40px;
  height: 100px;
  position: absolute;
  margin: 12px;
  border-radius: 2px;
  z-index: 9999999999999999;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  transition: all 200ms ease-out;
}

#in {
  width: 100%;
  height: 50%;
  position: relative;
  background: var(--theme-color);
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  overflow: hidden;
}
#in:hover {
  cursor: pointer;
  fill: black;
}

#out {
  width: 100%;
  height: 50%;
  position: relative;
  background: var(--theme-color);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  overflow: hidden;
}
#out:hover {
  cursor: pointer;
}

#minus-svg {
  transform: rotate(90deg);
}
#minus-svg:hover path {
  fill: #444;
}

#plus-svg:hover path {
  fill: #444;
}

#minus-svg path,
#plus-svg path {
  transition: all 200ms ease-out;
}

.c-button {
  margin: 0;
  background: #ffffff;
  /*box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);*/
  border: 0;
  transition: all 0.2s ease;
}
.c-button:hover,
.c-button:focus {
  outline: 0;
  text-decoration: none;
}
.c-button:not(:disabled) {
  cursor: pointer;
}

.c-ripple {
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: transparent;
}

.c-ripple__circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: 0;
  height: 0;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
}
.c-ripple.is-active .c-ripple__circle {
  animation: ripple 0.7s ease-in;
}

@keyframes ripple {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  100% {
    width: 700%;
    padding-bottom: 700%;
    opacity: 0;
  }
}
.element-pan {
  /* display: flex;
  justify-content: center; */
}

/* ============================ Zoom ============================= */
.backdrop {
  position: absolute; /* Ensure the backdrop covers the entire Panview */
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
}
.ev_zoom_center{
  display: flex;
  justify-content: center;
}
/** ================================================= END by Anubhav Sharma =========================================================== */

.image-item{
  height: 100%;
  width: 100%;
  object-fit: cover;
}